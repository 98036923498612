import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { DatasetModel } from '@shared/models/dataset.model';
import * as _ from 'lodash';
import { Data, ParamMap } from '@angular/router';
import { Papa, ParseResult } from 'ngx-papaparse';
import { Utils } from '@shared/utils';
import { TextDecoder } from 'text-encoding';
import { PackageSearchModel } from '@shared/models/package-search.model';
import { SearchFacetModel, SearchFacetResultItem } from '@shared/models/search.model';
import { DatastoreSearchModel } from '@shared/models/datastore-search.model';
import { DynamicInjectorService } from './dynamic.injector.service';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class DatasetsService {

  private _datasets: Map<string, DatasetModel> = new Map<string, DatasetModel>();
  private ckanUrl:string;
  filters: Map<string,string> = new Map<string,string>();
  filterTypes: SearchFacetResultItem[] = [];
  encoded: any;

  private packageSearchResponse = {"keyword":"", "count":0}

  constructor(
    private apiService: ApiService, 
    private papa: Papa, 
    private dynamicInjectorService:DynamicInjectorService,
    private translateService: TranslateService
    ) {
    this.ckanUrl = this.dynamicInjectorService.getEnvironment().apiUrl;
  }

  getDatasets(pageSize: number, pageNumber: number, autoCompleteField?: string, filters?: ParamMap, type?: string): Promise<PackageSearchModel> {
    const insertedFilters: Map<string, string> = this.convertParamMapToMap(filters);
    if(type){
      insertedFilters.set('type',type);
    }
    this.filters = insertedFilters;
    this.updatePaginatorState(pageNumber, this.apiService.getCurrentPackageSearchParams());
    return this.getPackageSearch(pageSize, pageNumber, autoCompleteField, insertedFilters);
  }

  /**
   * @todo looks like this function can return too many type options. Sounds like something we might want to divide.
   */
  getResourceData(url: string, type: string):Promise<string|ArrayBuffer|ParseResult> {
    const dataOrigin:string = this.dynamicInjectorService.getOrganizationConfig().startLanguage;

    if (type === 'CSV') {
      if (Utils.detectEdgeAndIE() !== false && Utils.detectEdgeAndIE() > 10) {

        if (!window['TextDecoder']) {
          window['TextDecoder'] = TextDecoder;
        }

        return this.apiService.getTextRequest(url).then(response => {
          const decoder = new TextDecoder('ISO-8859-1');
          const decoded = decoder.decode(response as unknown as BufferSource);
          if (this.detectHeaderDelimitor(response)) {
            /**
             * @todo: the response as unknown as BufferSource is very sketchy
             */
            // UTF decode for swedish customers
            if (dataOrigin === 'sv') {
              // verify original decoded data integrity - if invalid characters found, re-encode original data as UTF-8
              if (decoded.includes('¶') || decoded.includes('Ã') || decoded.includes('å') || decoded.includes('ö')) {
                const newDecoder:TextDecoder = new TextDecoder('UTF-8');
                const newDecoded = newDecoder.decode(response as unknown as BufferSource);

                return this.papa.parse(newDecoded, { header: true, skipEmptyLines: true, delimiter: ';', newline: '\n' });
              }
            }
            // No decode for non swedish municipalities
            return this.papa.parse(response, { header: true, skipEmptyLines: true, delimiter: ';', newline: '\n' });
          } else {
            // UTF decode for swedish customers
            if (dataOrigin === 'sv') {
              if (decoded.includes('¶') || decoded.includes('Ã') || decoded.includes('å') || decoded.includes('ö')) {
                const newDecoder = new TextDecoder('UTF-8');
                const newDecoded = newDecoder.decode(response as unknown as BufferSource);
                return this.papa.parse(newDecoded, { header: true, skipEmptyLines: true, newline: '\n' });
              }
            }
            // No decode for non swedish municipalities
            return this.papa.parse(response, { header: true, skipEmptyLines: true, newline: '\n' });
          }
        });

      } else {
        return this.apiService.getArrayBufferRequest(url).then(response => {
          const decoder = new TextDecoder('ISO-8859-1');
          const decoded = decoder.decode(response);

          if (this.detectHeaderDelimitor(decoded)) {
            // UTF decode for swedish customers
            if ( dataOrigin === 'sv') {
              // verify original decoded data integrity - if invalid characters found, re-encode original data as UTF-8
              if (decoded.includes('¶') || decoded.includes('Ã') || decoded.includes('å') || decoded.includes('ö')) {
                const newDecoder = new TextDecoder('UTF-8');
                const newDecoded = newDecoder.decode(response);
                return this.papa.parse(newDecoded, { header: true, skipEmptyLines: true, delimiter: ';', newline: '\n' });
              }
            }
            // ISO decode for non swedish customers
            return this.papa.parse(decoded, { header: true, skipEmptyLines: true, delimiter: ';', newline: '\n' });
          } else {

            if (dataOrigin === 'sv')  {
              if (decoded.includes('¶') || decoded.includes('Ã') || decoded.includes('å') || decoded.includes('ö')) {
                const newDecoder = new TextDecoder('UTF-8');
                const newDecoded = newDecoder.decode(response);
                return this.papa.parse(newDecoded, { header: true, skipEmptyLines: true, newline: '\n' });
              }
            }
            // ISO decode for non swedish customers
            return this.papa.parse(decoded, { header: true, skipEmptyLines: true, newline: '\n' });
          }
        });
      }
    }
    return this.apiService.getTextRequest(url);
  }

  detectHeaderDelimitor(data: string): boolean {
    const splittedData = data.split('\n');
    if (splittedData[0].includes(';')) {
      return true;
    }
    return false;
  }

  public postFeedback(userData: object, token:string): Promise<string> {
    return new Promise<string>((resolve,reject) => {
        this.apiService.postFeedback(userData, new HttpParams().append(ApiService.TOKEN_HEADER, token))
        .then(() => resolve(this.translateService.instant('REPLY.SERVER_SUCCESS'))
        ).catch((x: HttpErrorResponse) => 
            /**
             * @todo or do we want to reject this if an error pops up?
             */
            resolve(`${this.translateService.instant('REPLY.SERVER_ERROR')} ${x.status} ${x.statusText}`)
        );
    });
  }

  getTotalNumberOfDatasets(): Promise<number> {
    return new Promise<number>((resolve) => {
      this.getPackageSearch(0, 0, null, this.filters).then(response => {
        resolve(response.count)
      })
    })
  }

  // getDatasetDetails(datasetId: string, include_tracking: boolean = true): Promise<DatasetModel> {
  //   return new Promise((resolve) => {
  //     if (this._datasets.has(datasetId)) {
  //       resolve(this._datasets.get(datasetId));
  //     } else {
  //       this.apiService.getDatasetDetails(datasetId, include_tracking).then(response => resolve(response));
  //     }
  //   })
  // }

  getDatasetDetails(datasetId: string, include_tracking: boolean = true, include_definitions: boolean = true): Promise<DatasetModel> {
    let result: Promise<DatasetModel> = null;
    let definitions_included = false;
    // either get the definition from the local cache or do a definition_show
    if (this._datasets.has(datasetId)) {
      result = new Promise((resolve) => resolve(this._datasets.get(datasetId)))
      definitions_included = this._datasets.get(datasetId).definitions != undefined
    } else {
      result = this.apiService.getDatasetDetails(datasetId, include_tracking);
    }

    if (!definitions_included && include_definitions && this.dynamicInjectorService.hasPlugin('definitions')) {
      result = Promise.all([result, this.apiService.getDefinitionsByDataset(datasetId)]).then(([dataset, definitions]) => {
          dataset.definitions = definitions;
          return dataset;
      })
    }

    return result;
  }

  getDatastoreSearch(resourceId: string, limit: number = 100, offset: number = 0, include_total: boolean = true, sort?: string, fields?: string[] ): Promise<DatastoreSearchModel> {
    
    let params = new HttpParams();
    params = params.set('resource_id', resourceId);
    params = params.set('limit', limit.toString());
    params = params.set('offset', offset.toString());
    params = params.set('include_total', include_total.toString());
    if (sort != undefined) {
      params = params.set('sort', sort);
    }
    if (fields != undefined) {
      params = params.set('fields', fields.join(","));
      
    }
    return this.apiService.getDatastoreSearch(params);
  }

  /**
   * A function that returns a SearchFacetModel
   * @param filters include a map with parameters that will be included in the API call
   * @returns a SearchFacetModel
   */  
  public getFacetCounts(filters: Map<string,string>): Promise<SearchFacetModel> {
    return new Promise<SearchFacetModel>((resolve) => {
      this.getPackageSearch(0, 0, null, filters, null, true).then(response => resolve(response.search_facets));
    })
  }

  /**
   * This method returns a set of packages
   * @param pageSize number
   * @param pageNumber number
   * @param [autoCompleteField] string: (optional) value for the autocomplete field
   * @param [filters] ParamMap: (optional) filters to include
   * @param [sort] string: (optional) sorting option
   * @param [facet] boolean: (optional) should facets (counts) be included
   * @returns Observable<PackageSearchModel>
   */
  public getPackageSearch(pageSize: number, pageNumber: number, autoCompleteField?: string, filters?: Map<string,string>, sort?: string, facet?: boolean, onlyShowFields?:string[]): Promise<PackageSearchModel> {
    let params = new HttpParams();
    params = params.set('rows', pageSize.toString());
    params = params.set('start', (pageNumber * pageSize).toString());

    if (!_.isNil(facet) && facet) {
      params = params.set('facet.field', '["res_format","tags","groups","license_id","organization","private"]');
    }

    if (!_.isNil(sort)) {
      params = params.set('sort', sort);
    }

    let q: String[] = [`owner_org:(${this.dynamicInjectorService.getEnvironment().organizationId})`];

    if (filters != undefined && filters.size > 0) {
      filters.forEach((value,key) =>{
        switch (key) {
          case 'resources.format': q.push(`res_format:"${filters.get('resources.format')}"`);
            break;

          case 'tags.name': q.push(`tags:"${filters.get('tags.name')}"`);
            break;

          case 'groups.title': q.push(`groups:"${filters.get('groups.title')}"`);
            break;

          case 'license.id': q.push(`license_id:"${filters.get('license.id')}"`);
            break;

          case 'organization.name': q.push(`organization:"${filters.get('organization.name')}"`);
            break;

          case 'private': q.push(`private:"${filters.get('private')}"`);
            break;            

          default: q.push(`${key}:${value}`);
        }
      });
    }

    if (!_.isNil(autoCompleteField)) {
      q.push(`autocomplete_field:(${autoCompleteField})`);
    }

    let query = '*:*';
    if (q.length > 0) {
      query = q.join(' AND ');
    }
    params = params.set('q', query);

    if(onlyShowFields && onlyShowFields.length > 0){
      params = params.set('fl',onlyShowFields.join(' AND '));
    }
    this.packageSearchResponse.keyword = autoCompleteField
    return this.apiService.getPackageSearch(params).then(response => {
      this.packageSearchResponse.count = response.count
      return (response as PackageSearchModel);
    })
  };

  public getPackageSearchResponse(){
    return this.packageSearchResponse
  }

  /**
   * This method returns the list of available carousel packages
   * @param filters ParamMap: (optional) filters to include
   * @param sort string: (optional) sorting option
   * @returns Promise<PackageSearchModel>
   */
  getCarouselDatasets(filters?: string, sort?: string): Promise<PackageSearchModel> {
    let params = new HttpParams({
      encoder: new CustomEncoder()
    });
    const carouselPackageFilterParam = 'carousel_active:True'
    // Result shall correspond to the organizations of the instance
    params = params.set('q',carouselPackageFilterParam);
    params = params.set('fq',filters);
    params = params.set('sort', sort);

    return this.apiService.getPackageSearch(params).then(response => {
      return (response as PackageSearchModel);
    });
  }

  /**
   * This method returns the list of available packages of type 'news'
   * @param organizationId string: organization/s of current instance
   * @returns Promise<PackageSearchModel>
   */
  getNewsPackageSearch(organizationId: string): Promise<PackageSearchModel> {
    let params = new HttpParams({
      encoder: new CustomEncoder()
    });
    const newsParams = `owner_org:${organizationId} AND type:news`
    params = params.set('q',newsParams);
    params = params.set('sort', 'metadata_created desc');
    return this.apiService.getPackageSearch(params).then(response => {
      return (response as PackageSearchModel);
    });
  }

  /**
   * Clears the local cache and fills it with the given array of DatasetModels
   **/
  setLocalCache(datasets: DatasetModel[]) {
    this._datasets = new Map<string, DatasetModel>();
    datasets.map(dataset => this._datasets.set(dataset.id, dataset));
  }

  public getCkanUrl():string{
    return this.ckanUrl;
  }

  private convertParamMapToMap(paramMap:ParamMap):Map<string,string>{
    //I don't know a better way to do this.
    const filterMap:Map<string,string> = new Map<string,string>();
    if (paramMap != undefined){
      paramMap.keys.forEach(key => filterMap.set(key,paramMap.get(key)));
    }
    return filterMap;
  }

  /**
   * Update pageIndex when package search parameters change, to properly display the available data
   * @param page_number 
   * @param current_package_search_params 
   */

  updatePaginatorState(page_number, current_package_search_params){
    const last_package_search_params  = sessionStorage.getItem("packageSearchParams")
    // If equal, pageIndex shall remain unchanged
    if (current_package_search_params !== last_package_search_params){
        sessionStorage.setItem("pageIndex", "0");
    }
    else {
      sessionStorage.setItem("pageIndex", page_number.toString());
    }
    sessionStorage.setItem("packageSearchParams", current_package_search_params);
  }

  addItemToWishList(dataset: DatasetModel): void{
    const itemExists = this.isItemInWishlist(dataset.id);
    if (itemExists){
      return
    }

    let wishList: WishListItem[] = []
    const wishListItem: WishListItem = {
      id: dataset.id,
      title: dataset.title,
      routerLink: `/data/${dataset.id}`,
      owner: dataset.contact_point_name,
      updated: dataset.metadata_modified,
      theme: dataset.theme, //upgrade for more than one
    }
    const storedWishListContent = this.getWishListContent()
    if (storedWishListContent != undefined && storedWishListContent.length !== 0) {
      wishList = storedWishListContent
    }
    wishList.push(wishListItem)
    sessionStorage.setItem("wishList", JSON.stringify(wishList));
  }

  isItemInWishlist(id): boolean{
    const storedWishListContent = this.getWishListContent()
    if (storedWishListContent == undefined || storedWishListContent.length === 0) {
      return false
    }
    const itemExists = storedWishListContent.some(item => item.id === id);
    return itemExists
  }

  getWishListContent(): any {
    const wishList = sessionStorage.getItem("wishList")
    let wishListContent;
    if (wishList){
      wishListContent = JSON.parse(wishList)
    }
    return wishListContent
  }

  removeItemFromWishList(id): void{
    const storedWishListContent = this.getWishListContent()
    // Find the index of the item by dataset.id
    const itemIndexToRemove = storedWishListContent.findIndex(item => item.id === id);
    if (itemIndexToRemove !== -1) {
      // If the object with the target "id" was found, remove it using splice
      storedWishListContent.splice(itemIndexToRemove, 1);
      sessionStorage.setItem("wishList", JSON.stringify(storedWishListContent));
    }
  }

  public isWishListEmpty(): boolean {
    const wishList = this.getWishListContent()
    if (wishList == undefined || wishList.length === 0){
      return true
    }
    return false
  }

  addtoRequestList(item): void{
    const requestListContent = this.getRequestListContent()
    sessionStorage.setItem("requestListObject", "");
  }

  getRequestListContent(): any {
    const requestList = sessionStorage.getItem("requestListObject")
    let requestListContent;
    if (requestList){
      requestListContent = JSON.parse(requestList)
    }
    return requestListContent
  }

  removeFromRequestList(item): void{
    sessionStorage.removeItem("requestListObject");
  }
}

export interface WishListItem {
  id: string;
  title: string;
  routerLink: string;
  owner: string;
  updated: Date;
  theme: string;
}

/**
 * By default the standard HttpParameterCodec (HttpUrlEncodingCodec) uses the encodeURIComponent for each value,
 * except it then decodes (excludes) a small sample of characters one of which is '+'. This custom encoder basically
 * re-encodes the '+' into its encoded value '%2B'.
 * See: https://github.com/angular/angular/blob/5298b2bda34a8766b28c8425e447f94598b23901/packages/common/http/src/params.ts#L57
 */
class CustomEncoder extends HttpUrlEncodingCodec {
  encodeValue(value: string): string {
      return super.encodeValue(value).replace(/\+/gi,'%2B');
  }
}