import { DatasetModel } from "./dataset.model";
import { SearchModel } from "./search.model";

export class DefinitionModel {
  id: string;
  label: string;
  description: string;
  discipline?: string;
  expertise?: string;
  url: string;
  enabled: boolean;
  creator_id: string;
  created_date: Date;
  modified_date: Date;
  datasets?: DatasetModel[]

  hasDatasets(): boolean {
    return this.datasets != undefined &&  this.datasets.length > 0;
  }  
}

export class DefinitionSearchModel extends SearchModel<DefinitionModel> {}