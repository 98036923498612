import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { DatasetModel } from '@shared/models/dataset.model';
import { ApiService } from '@shared/services/api.service';
import { ID } from '../id';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { PortalConfigComponent } from '../../../portal-config.component';

@Component({
  selector: 'app-dataplatform-home-text',
  templateUrl: './dataplatform-home-text.component.html',
  styleUrls: ['./dataplatform-home-text.component.scss']
})

@Injectable({
  providedIn: 'root'
})
export class DataplatformHomeTextComponent extends PortalConfigComponent implements OnInit, ConfigInterface {
  public mostSeen: DatasetModel[] = [];
  public lastsEdited: DatasetModel[] = [];

  constructor(
    private apiService: ApiService,
    dynamicInjectorService:DynamicInjectorService)
    {
      super(dynamicInjectorService);
     }

  getPortal(): string {
      return ID.id;
  }

  ngOnInit() {
        /**
     * @todo needs a type as result
     */
    this.apiService.getLastEditedDatasets().then(result => {
      this.lastsEdited = result.results.slice(0, 5);
    });

    /**
     * @todo needs a type as result
     */
    this.apiService.getMostSeen().then(result => {
      this.mostSeen = result.results.slice(0, 5);
    });
  }
  
  getAsset(path) {
    return `${this.dynamicInjectorService.getCustomerConfigPath()}/assets/${path}`
  }
}
