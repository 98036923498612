      <br />
      <div fxLayout="column"
        style="margin-left: 15% ; width: 70% ; margin-bottom: 16px ; align-items: center ; margin-top: 30px ">
        <img class="banner-image" [src]="homeBanner">
        <br><br>
        <h1 class="mat-h1" role="heading">{{ homeTitle }}</h1>
        <p style="text-align: justify; margin-bottom: 16px">
          {{ homeDescription }}
        </p>
      </div>
