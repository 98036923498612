import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { DefinitionsService } from '@shared/services/definitions.service';
import { DefinitionModel } from '@shared/models/definition.model';

@Injectable()
export class DefinitionDetailsResolver implements Resolve<DefinitionModel> {

  constructor(private definitionsService: DefinitionsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<DefinitionModel> {
    console.debug(`resolving this.definitionsService.getDefinitionDetails(${route.paramMap.get('id')})`)
    return this.definitionsService.getDefinitionDetails(route.paramMap.get('id'));
  }
}
