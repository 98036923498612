export class SearchModel<T>  {
    count: number;
    facets: any;
    results: T[];
    search_facets: SearchFacetModel;
    sort: string;
}

export class SearchFacetModel {
    [key: string]: SearchFacetResult;
}

class SearchFacetResult {
    title: string;
    items: SearchFacetResultItem[];
}

export class SearchFacetResultItem {
    count: number;
    display_name: string;
    name: string;
}
