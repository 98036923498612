import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { NavigationService } from '@shared/services/navigation.service';
import { ID } from '../id';
import en from './../assets/i18n/en.json';
import sv from './../assets/i18n/sv.json';

@Component({
  selector: 'app-malmo-home-text',
  templateUrl: './malmo-home-text.component.html',
  styleUrls: ['./malmo-home-text.component.scss']
})
export class MalmoHomeTextComponent implements OnInit, ConfigInterface {

  homeTitle: string;
  homeDescription: string;
  homeBanner: string;

  constructor(
    private translateService: TranslateService,
    private navigationService: NavigationService
  ) { }

  getPortal(): string {
    return ID.id;
  }

  ngOnInit(): void {
    // Set the initial language from translateService
    this.loadTranslationTexts();

    // Listen for language changes
    this.translateService.onLangChange.subscribe(() => {
      this.loadTranslationTexts();

    });
  }

  public getDataRoute():string{
    return this.navigationService.getDataRoute();
  }

  /*
  * Function to retrieve translations from json objects, identical to portal-core
  * TODO this approach is a first step towards first merging translations from core and config together
  * Final goal is to move all translation dictionaries under portal-config, and make this function
  * universal and available to all organizations
  */
  loadTranslationTexts(): void {
    // assign translation imports in a var
    const availableTranslations = { en, sv }
    // select language translation import
    const translationDict = availableTranslations[this.translateService.currentLang];
    this.homeTitle = translationDict.HOME?.TITLE || '<add title>';
    this.homeDescription = translationDict.HOME?.DESCRIPTION || '<add description>';
    this.homeBanner = translationDict.HOME?.BANNER;
  }
}
