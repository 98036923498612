import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { ParamMap } from '@angular/router';
import { DynamicInjectorService } from './dynamic.injector.service';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';
import { DefinitionModel, DefinitionSearchModel } from '@shared/models/definition.model';
import { SearchFacetModel, SearchFacetResultItem } from '@shared/models/search.model';
import { DatasetModel } from '@shared/models/dataset.model';


@Injectable()
export class DefinitionsService {

  private _definitions: Map<string, DefinitionModel> = new Map<string, DefinitionModel>();
  private ckanUrl:string;
  filters: Map<string,string> = new Map<string,string>();
  filterTypes: SearchFacetResultItem[] = [];
  encoded: any;

  private definitionSearchResponse = {"keyword":"", "count":0}

  constructor(
    private apiService: ApiService, 
    private dynamicInjectorService:DynamicInjectorService,
    private translateService: TranslateService
    ) {
    this.ckanUrl = this.dynamicInjectorService.getEnvironment().apiUrl;
  }

  getDefinitions(pageSize: number, pageNumber: number, query?: string, filters?: ParamMap): Promise<DefinitionSearchModel> {
    const insertedFilters: Map<string, string> = this.convertParamMapToMap(filters);
    this.filters = insertedFilters;
    this.updatePaginatorState(pageNumber, this.apiService.getCurrentDefinitionSearchParams());
    return this.getDefinitionSearch(pageSize, pageNumber, query, insertedFilters);
  }

  getTotalNumberOfDefinitions(): Promise<number> {
    return new Promise<number>((resolve) => {
      this.getDefinitionSearch(0, 0, null, this.filters).then(response => {
        resolve(response.count)
      })
    })
  }

  getDefinitionDetails(definitionId: string, include_datasets: boolean = true): Promise<DefinitionModel> {
    let result: Promise<DefinitionModel> = null;
    let datasets_included = false;
    // either get the definition from the local cache or do a definition_show
    if (this._definitions.has(definitionId)) {
      result = new Promise((resolve) => resolve(this._definitions.get(definitionId)))
      datasets_included = this._definitions.get(definitionId).datasets != undefined
    } else {
      result = this.apiService.getDefinitionShow(definitionId);
    }

    if (!datasets_included && include_datasets) {
      result = Promise.all([result, this.getDatasetsForDefinition(definitionId)]).then(([definition, datasets]) => {
          definition.datasets = datasets;
          return definition;
      })
    }

    return result;
  }

  getDatasetsForDefinition(definitionId: string): Promise<DatasetModel[]> {
    return this.apiService.getDatasetsByDefinition(definitionId);
  }

  /**
   * A function that returns a SearchFacetModel
   * @param filters include a map with parameters that will be included in the API call
   * @returns a SearchFacetModel
   */  
  public getFacetCounts(filters: Map<string,string>): Promise<SearchFacetModel> {
    return new Promise<SearchFacetModel>((resolve) => {
      this.getDefinitionSearch(0, 0, null, filters, null, true).then(response => resolve(response.search_facets));
    })
  }

  /**
   * This method returns a set of packages
   * @param pageSize number
   * @param pageNumber number
   * @param [autoCompleteField] string: (optional) value for the autocomplete field
   * @param [filters] ParamMap: (optional) filters to include
   * @param [sort] string: (optional) sorting option
   * @param [facet] boolean: (optional) should facets (counts) be included
   * @returns Observable<PackageSearchModel>
   */
  public getDefinitionSearch(pageSize: number, pageNumber: number, query?: string, filters?: Map<string,string>, sort?: string, facet?: boolean): Promise<DefinitionSearchModel> {
    let include_all = true;
    let include_disabled = true;
    let offset = pageNumber * pageSize;
    this.definitionSearchResponse.keyword = query
    let params: HttpParams = new HttpParams()
      .set('include_all', include_all)
      .set('include_disabled', include_disabled)
      .set('offset', offset)
      .set('limit', pageSize);

    if (!_.isNil(sort)) {
        params = params.set('sort', sort);
    }
    if (!_.isNil(query)) {
        params = params.set('q', query);
    }
    if (!_.isNil(facet)) {
      params = params.set("facet", facet);
      if (facet) {
        ["creator_id", "enabled", "label"].forEach((facet_field) => {
          params = params.append('facet.field', facet_field);
        });
      }
    }

    if (filters != undefined && filters.size > 0) {
      filters.forEach((value,key) => { params = params.set(key, value); });
    }

    return this.apiService.getDefinitionSearch(params).then(response => {
      this.definitionSearchResponse.count = response.count
      return (response as DefinitionSearchModel);
    })

  };

  public getDefinitionSearchResponse(){
    return this.definitionSearchResponse
  }

  /**
   * Clears the local cache and fills it with the given array of DatasetModels
   **/
  setLocalCache(definitions: DefinitionModel[]) {
    this._definitions = new Map<string, DefinitionModel>();
    definitions.map(definition => this._definitions.set(definition.id, definition));
  }

  public getCkanUrl():string{
    return this.ckanUrl;
  }

  private convertParamMapToMap(paramMap:ParamMap):Map<string,string>{
    //I don't know a better way to do this.
    const filterMap:Map<string,string> = new Map<string,string>();
    if (paramMap != undefined){
      paramMap.keys.forEach(key => filterMap.set(key,paramMap.get(key)));
    }
    return filterMap;
  }

  /**
   * Update pageIndex when package search parameters change, to properly display the available data
   * @param page_number 
   * @param current_definition_search_params 
   */

  updatePaginatorState(page_number, current_definition_search_params){
    const last_definition_search_params  = sessionStorage.getItem("definitionSearchParams")
    // If equal, pageIndex shall remain unchanged
    if (current_definition_search_params !== last_definition_search_params){
        sessionStorage.setItem("definitionPageIndex", "0");
    }
    else {
      sessionStorage.setItem("definitionPageIndex", page_number.toString());
    }
    sessionStorage.setItem("definitionSearchParams", current_definition_search_params);
  }

}
